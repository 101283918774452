import styles from './VSL.module.css';
import Image from 'next/image';

const VSL = ({ data, videoRef }: any) => {
  return (
    <div className={styles.vslContainer}>
      <div className={styles.videoContainer}>
        <iframe
          ref={videoRef}
          className={`${styles.responsiveIframe} w-full md:w-3/4 lg:w-1/2`}
          src={data?.video}
          title="Vídeo de Vendas"
          allowFullScreen></iframe>
      </div>

      {/* msg para verificar se o som está ativado */}
      <div className="flex items-center justify-center text-center my-4 font-bold gap-2">
        <div className="flex items-center justify-center gap-2 animate-scale w-fit">
          <Image
            src="/soundIcon-fa5f30b5.gif"
            width={24}
            height={24}
            alt="Icono de sonido"
          />
          <p className="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">
            {data?.soundDiv}
          </p>
        </div>
      </div>
    </div>
  );
};

export default VSL;
