'use client';
import React, { useState, useEffect } from 'react';

const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowButton(scrollY > 200);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <button
      onClick={scrollToTop}
      className={`z-50 fixed right-4 bottom-4 p-3 rounded-full crm-primary-bg text-white shadow-lg transition-opacity duration-300 ${
        showButton ? 'opacity-70' : 'opacity-0'
      }`}
      aria-label="Scroll to top">
      <svg
        className="w-6 h-6 mx-auto"
        fill="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          d="M12 5.293l-4.707 4.707a1 1 0 01-1.414-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L12 5.293zM12 12.293l-4.707 4.707a1 1 0 01-1.414-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L12 12.293z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  );
};

export default BackToTopButton;
