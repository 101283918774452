// Importa o estilo do componente
import style from "./FacebookComments.module.css";
// Importa o componente Container para estruturar o layout
import { Container } from "../../../../container/Container";
// Importa o componente Image do Next.js para otimizar o carregamento de imagens
import Image from "next/image";

// Define a interface para os comentários, especificando os campos que cada comentário deve ter
interface Comment {
  fotoPerfil: string; // URL da foto de perfil do usuário
  nomeUsuario: string; // Nome do usuário que fez o comentário
  assunto?: string; // Assunto do comentário (opcional)
  comentario: string; // Texto do comentário
  like: string; // Número de likes do comentário
  tempo: string; // Tempo desde que o comentário foi postado
  reply?: Comment; // Comentário de resposta (opcional)
}

// Componente principal que exibe a seção de comentários do Facebook
const FacebookComments = ({ data }: { data: Comment[] }) => {
  return (
    <div className={style.fbCommentsBg}>
      <Container>
        <div className="bg-white rounded-md border border-gray-300 p-4 my-12 w-full">
          {/* Exibe o número total de comentários e permite ordená-los */}
          <div className="flex flex-col sm:flex-row justify-between mb-4">
            <span className="font-semibold text-sm sm:text-base mb-2 sm:mb-0">
              {data.length} Comentários
            </span>
            <div className="flex items-center">
              <span className="mr-2 text-sm sm:text-base">Ordenar por</span>
              <select className="border border-gray-300 rounded px-2 py-1 text-sm">
                <option>Top</option>
                <option>Novos</option>
                <option>Antigos</option>
              </select>
            </div>
          </div>
          {/* Campo para adicionar um novo comentário */}
          <div className="flex items-start mt-4">
            <Image
              src="https://static.xx.fbcdn.net/rsrc.php/v1/yi/r/odA9sNLrE86.jpg"
              alt="Avatar"
              width={40}
              height={40}
              className="rounded-full mr-2 sm:mr-4"
              unoptimized
            />
            <div className="flex-1 border border-gray-300 rounded-md p-4 relative w-full">
              <textarea
                placeholder="Escreva um comentário..."
                className="w-full border-none focus:outline-none focus:ring-0 text-xs sm:text-base"
                rows={3}
              />
              <button className="bg-blue-600 text-white font-bold px-2 sm:px-4 py-2 rounded absolute bottom-2 right-2 text-xs sm:text-sm">
                Publicar
              </button>
            </div>
          </div>
          {/* Lista de comentários */}
          <ul className="mt-4">
            {data.map((comment: Comment, index: number) => (
              <CommentItem key={index} comment={comment} />
            ))}
          </ul>
          {/* Botão para carregar mais comentários */}
          <button className="w-full text-xs sm:text-sm bg-sky-800 text-white font-bold py-2 sm:py-3 rounded-md">
            Carregar mais comentários
          </button>
          {/* Informações sobre o plugin do Facebook */}
          <div className="flex items-center mt-4">
            <Image
              src="/fb-comments/fb-logo.svg"
              alt="Facebook"
              width={14}
              height={14}
            />
            <p className="text-xs text-gray-600 ml-2">
              Plugin social do Facebook
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

// Componente auxiliar para renderizar um comentário individual
const CommentItem = ({ comment }: { comment: Comment }) => {
  return (
    <li className="py-4 border-b border-gray-200">
      <div className="flex flex-row sm:flex-row">
        {/* Exibe a foto de perfil do usuário */}
        <div className="mr-3 sm:mr-4 pt-3">
          <Image
            src={comment.fotoPerfil}
            width={32}
            height={32}
            alt="Avatar"
            className="rounded-full"
            unoptimized
          />
        </div>
        <div className="flex-1">
          {/* Exibe o nome do usuário e o comentário */}
          <div className="bg-gray-100 p-3 sm:p-4 rounded-lg">
            <h4 className="font-semibold text-blue-600 text-sm sm:text-base">
              {comment.nomeUsuario}
            </h4>
            <p className="text-sm sm:text-base">{comment.comentario}</p>
          </div>
          {/* Exibe informações adicionais do comentário */}
          <div className="flex flex-wrap items-center mt-2 text-xs sm:text-sm text-gray-600">
            <button className="mr-2 hover:underline text-blue-600 cursor-pointer">
              Curtir
            </button>
            <span className="mr-2"></span>
            <button className="hover:underline text-blue-600 cursor-pointer">
              Comentar
            </button>
            <span className="mx-1 sm:mx-2">
              <Image
                src="/fb-comments/fb-like.svg"
                alt="like"
                width={10}
                height={10}
                className="sm:w-3 sm:h-3"
              />
            </span>
            <span>{comment.like}</span>
            <span className="mx-1"></span>
            <span className="text-gray-400">{comment.tempo} min</span>
          </div>
          {/* Renderiza um comentário de resposta, se houver */}
          {comment.reply && (
            <ul key={comment.reply.nomeUsuario}>
              <CommentItem comment={comment.reply} />
            </ul>
          )}
        </div>
      </div>
    </li>
  );
};

export default FacebookComments;
