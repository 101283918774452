// importando o tipo ReactNode do React para tipar a propriedade children
import { ReactNode } from 'react';

// props do componente Container
interface ContainerProps {
  children: ReactNode;
}

// esse componente Container centraliza o conteúdo todo e aplica estilos de largura máxima e margens práticas
export function Container({ children }: ContainerProps) {
  return (
    // div com classes do Tailwind CSS para centralizar o conteúdo e aplicar estilos de largura máxima e margens automáticas
    <div className="w-full max-w-screen-lg px-4 mx-auto flex flex-col items-center justify-center">
      {/* renderiza o conteúdo passado como children */}
      {children}
    </div>
  );
}
