'use client';
import { Container } from '@/app/container/Container';
import { useEffect, useState } from 'react';

interface TimerProps {
  isPvBg?: boolean;
}

const Timer = ({ isPvBg = false }: TimerProps) => {
  const initialTimeInSeconds = 2 * 3600 + 29 * 60 + 59;
  const [secondsLeft, setSecondsLeft] = useState(initialTimeInSeconds);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (secondsLeft > 0) {
        setSecondsLeft(secondsLeft - 1);
      } else {
        clearInterval(timerInterval);
        window.location.hash = 'sec-c2dc';
      }
    }, 1000);
    return () => clearInterval(timerInterval);
  }, [secondsLeft]);

  const hours = Math.floor(secondsLeft / 3600);
  const minutes = Math.floor((secondsLeft % 3600) / 60);
  const seconds = secondsLeft % 60;

  return (
    <div
      className={`font-bold uppercase py-8 ${isPvBg ? 'crm-primary-bg text-white' : 'bg-white crm-primary-text'}`}>
      <Container>
        <h2 className="text-xl mb-4 text-center">
          Oferta por Tiempo Limitado. Los Precios Pueden Aumentar Después De:
        </h2>
        <div className="flex justify-center items-start text-3xl">
          <div className="flex flex-col items-center justify-center mx-2 gap-2">
            <span className="countdown">
              <span style={{ '--value': hours } as React.CSSProperties}></span>
            </span>
            <span className="text-xs">Horas</span>
          </div>
          <span>:</span>
          <div className="flex flex-col items-center justify-center mx-2 gap-2">
            <span className="countdown">
              <span
                style={{ '--value': minutes } as React.CSSProperties}></span>
            </span>
            <span className="text-xs">Minutos</span>
          </div>
          <span>:</span>
          <div className="flex flex-col items-center justify-center mx-2 gap-2">
            <span className="countdown">
              <span
                style={{ '--value': seconds } as React.CSSProperties}></span>
            </span>
            <span className="text-xs">Segundos</span>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Timer;
