import(/* webpackMode: "eager" */ "/frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["LocalStorage"] */ "/frontend/src/app/proxy/LocalStorage.tsx");
;
import(/* webpackMode: "eager" */ "/frontend/src/app/templates/BackIntentScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/app/templates/backredirect-latam/components/Header/Timer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/app/templates/backredirect/tabelas/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/app/templates/pv-brasil/backToTopButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/app/templates/pv-brasil/components/Main/Produtos/Produtos.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/app/templates/pv-brasil/components/Main/Timer/Timer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/app/templates/pv-brasil/SideNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/app/templates/pv-latam/backToTopButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/app/templates/pv-latam/components/Main/Timer/Timer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/app/templates/vsl-brasil/vsl-brasil-template.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/app/templates/vsl-latam/vsl-latam-template.tsx");
