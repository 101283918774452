import { Container } from '../../../../container/Container';

const Footer = ({ data }: any) => {
  return (
    <footer className="bg-black">
      <Container>
        <div className="flex flex-col p-5 mx-auto text-white gap-4 text-justify">
          <p>
            La información que proporcionamos no tiene la intención de sustituir
            la consulta con un profesional médico calificado. Le recomendamos
            informar a su médico sobre los cambios que realiza en su estilo de
            vida y discutirlos con él o ella. Para preguntas o inquietudes sobre
            cualquier condición médica que pueda tener, comuníquese con su
            médico. El contenido del sitio web y el producto en venta se basan
            en la opinión del autor y se proporcionan únicamente &quot;TAL
            CUAL&quot; y &quot;SEGÚN DISPONIBILIDAD&quot;.
          </p>
          <p>
            El contenido del sitio web y el producto a la venta se basan en la
            opinión del autor y se proporcionan únicamente &quot;COMO ESTN&quot;
            y &quot;SEGÚN DISPONIBILIDAD&quot;. Debe realizar su propia
            investigación y confirmar la información con otras fuentes al buscar
            datos sobre problemas de salud, y siempre revisar la información
            cuidadosamente con su salud, y siempre revisar la información
            cuidadosamente con su profesional de la salud antes de utilizar
            cualquiera de los protocolos presentados en este sitio y/o en el
            producto vendido aquí.
          </p>
          <p>
            Los testimonios, estudios de caso y ejemplos encontrados en esta
            página son resultados que nos han enviado usuarios de{' '}
            {data?.footer?.nomeProduto} y pueden no reflejar la experiencia
            típica del comprador, quizás no se apliquen a una persona común y no
            pretenden representar ni garantizar que cualquier persona logrará
            los mismos resultados o resultados similares. Algunos nombres e
            información de identificación personal en este sitio han sido
            modificados para proteger la privacidad de los individuos.
          </p>
          <p className="text-center text-stone-500">
            {data?.footer?.direitosReservados}
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
