'use client';
import React, { useState } from 'react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import Link from 'next/link';

const SideNavigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      {/* Botão para abrir o menu */}
      <button
        onClick={toggleMenu}
        className="p-2 text-white hover:text-zinc-300 md:hidden">
        {isOpen ? (
          <XIcon className="h-8 w-8" />
        ) : (
          <MenuIcon className="h-8 w-8" />
        )}
      </button>

      {/* Drawer Container */}
      {isOpen && (
        <div className="fixed inset-0 z-40">
          {/* Overlay para escurecer o fundo ao abrir o menu */}
          <div
            className="fixed inset-0 bg-black bg-opacity-50"
            onClick={toggleMenu}></div>

          {/* Drawer Sidebar */}
          <div className="absolute right-0 top-0 bottom-0 crm-primary-bg text-lg text-white w-64 p-4 transition-transform transform translate-x-0">
            {/* Conteúdo do menu */}
            <div className="flex justify-between items-center hover:brightness-90">
              <button onClick={toggleMenu} className="text-white">
                <XIcon className="h-6 w-6" />
              </button>
            </div>
            <ul className="mt-8 flex flex-col space-y-2">
              <li className="py-2">
                <Link
                  href="#produtos"
                  title="Produtos"
                  aria-label="Produtos"
                  onClick={toggleMenu}
                  className="py-2 hover:brightness-90 transition-colors">
                  Experimente Agora
                </Link>
              </li>
              <li className="py-2">
                <Link
                  href="#faq"
                  title="Perguntas Frequentes"
                  aria-label="Perguntas Frequentes"
                  onClick={toggleMenu}
                  className="py-2 hover:brightness-90 transition-colors">
                  Perguntas Frequentes
                </Link>
              </li>
              <li className="py-2">
                <Link
                  href="#garantia"
                  title="Garantia"
                  aria-label="Garantia"
                  onClick={toggleMenu}
                  className="py-2 hover:brightness-90 transition-colors">
                  Garantia
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default SideNavigation;
