import Image from 'next/image';
import { useEffect, useState } from 'react';

const ProductCard = ({ data }: any) => {
  const [queryParams, setQueryParams] = useState<string>('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParams = window.location.search;
      setQueryParams(
        searchParams.startsWith('?') ? searchParams.slice(1) : searchParams
      );
    }
  }, []);

  const getCheckoutUrlWithParams = (checkoutUrl: string) => {
    const url = new URL(checkoutUrl);
    if (queryParams) {
      const existingParams = url.searchParams.toString();
      url.search = existingParams
        ? `${existingParams}&${queryParams}`
        : `?${queryParams}`;
    }
    return url.toString();
  };

  const handleProductClick = (link: string) => {
    window.location.href = getCheckoutUrlWithParams(link);
  };

  return (
    <section className="flex flex-col xl:flex-row items-center justify-center gap-6 md:gap-4 my-14 shadow-sm">
      {data.map((produto: any, index: number) => {
        const semDesconto = parseFloat(
          produto.semDesconto.replace(/[^\d,]/g, '').replace(',', '.')
        );
        const precoAtual = parseFloat(
          produto.precoAtual.replace(/[^\d,]/g, '').replace(',', '.')
        );
        const desconto = semDesconto - precoAtual;

        return (
          <div
            key={index}
            onClick={() => handleProductClick(produto.checkout)}
            className={`flex flex-col items-center gap-2 w-full md:w-96 shadow-md shadow-gray-500 rounded-md font-bold text-center bg-white z-10 cursor-pointer border ${produto.recomendado ? 'crm-primary-border' : 'border-gray-200'}`}>
            {produto.recomendado && (
              <div className="w-full crm-primary-bg text-white p-3 text-base font-bold">
                MAIS VENDIDO
              </div>
            )}
            <div className="p-3">
              <h3 className="text-xl mt-4">{produto.tempoTratamento}</h3>
              <h4 className="text-lg mb-2">{produto.infoCaixa}</h4>
              <div className="flex items-center justify-center">
                <Image
                  src={produto.imagem}
                  width={300}
                  height={300}
                  alt={`kit-${index + 1}`}
                  className="transition-transform duration-500 transform hover:scale-110"
                  unoptimized
                />
              </div>
              <div className="flex items-start justify-center crm-primary-text font-bold mt-2 gap-1">
                <span className="text-base">
                  {produto.parcela}X<br /> DE R$
                </span>
                <span className="text-5xl">{produto.precoParcela}</span>
              </div>
              <div className="text-red-600 font-bold mt-1 animate-pulse">
                ÚLTIMOS {produto.kitsRestantes} KITS
              </div>
              <div className="text-gray-600 text-base mt-1">
                De R${produto.semDesconto} por R${produto.precoAtual}
              </div>
              <div className="text-base font-bold mt-1">
                Desconto de{' '}
                <span className="text-green-600">R${desconto.toFixed(2)}</span>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleProductClick(produto.checkout);
                }}
                className="w-full crm-bg-button text-black font-bold p-3 rounded-md mt-4 hover:brightness-110 transition-colors duration-300">
                Comprar Agora!
              </button>
              <div className="flex justify-center items-center mt-2">
                <Image
                  src="/bandeiras-cartoes.png"
                  alt="Bandeiras de cartões"
                  width={400}
                  height={400}
                  className="w-full h-full"
                />
              </div>
              <div
                className={`text-base mt-2 ${produto.frete.toLowerCase().includes('grátis') ? 'text-green-600' : 'text-gray-800'}`}>
                {produto.frete}
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default ProductCard;
