'use client'

import { useEffect } from "react";

export function LocalStorage() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams: { [key: string]: string } = {};
    urlParams.forEach((value, key) => {
      utmParams[key] = value;
    });
    localStorage.setItem('utmParams', JSON.stringify(utmParams));
  }, [])
  
  return null;
}