import style from './ReferenciaCientifica.module.css';
import { Container } from '../../../../container/Container';
import Image from 'next/image';

interface ReferenciaData {
  titulo: string;
  imagemReferencia: string;
}

const ReferenciaCientifica = ({ data }: { data: ReferenciaData }) => {
  return (
    <div className={`${style.referenciaBg} py-8`}>
      <Container>
        <div className="text-center">
          <h2 className="text-xl sm:text-2xl font-bold mb-4">{data.titulo}</h2>
          <Image
            src={data.imagemReferencia}
            alt={data.titulo}
            width={500}
            height={500}
            className="mx-auto mb-6 max-w-full h-auto"
            unoptimized
          />
        </div>
      </Container>
    </div>
  );
};

export default ReferenciaCientifica;
