'use client';

import style from './Garantia.module.css';
import { useState, useEffect } from 'react';

const GarantiaRef = ({ data }: any) => {
  const initialHours = 2;
  const initialMinutes = 30;
  const initialSeconds = 0;

  const [hours, setHours] = useState(initialHours);
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const timer = setInterval(() => {
      if (hours === 0 && minutes === 0 && seconds === 0) {
        setHours(initialHours);
        setMinutes(initialMinutes);
        setSeconds(initialSeconds);
      } else if (seconds === 0) {
        if (minutes === 0) {
          setHours(hours - 1);
          setMinutes(59);
          setSeconds(59);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [hours, minutes, seconds]);

  return (
    <section className="px-4 text-lg text-center">
      <h2 className="font-bold text-xl">{data?.titulo}</h2>
      <ul className="flex items-center justify-center my-10 mx-auto gap-4">
        <li className="flex flex-col items-center">
          <span
            className={`${style.spanBorder} border-2 rounded-full font-bold mb-2 p-3`}>
            {hours < 10 ? `0${hours}` : hours}
          </span>
          horas
        </li>
        <li className="flex flex-col items-center">
          <span
            className={`${style.spanBorder} border-2 rounded-full font-bold mb-2 p-3`}>
            {minutes < 10 ? `0${minutes}` : minutes}
          </span>
          min
        </li>
        <li className="flex flex-col items-center">
          <span
            className={`${style.spanBorder} border-2 rounded-full font-bold mb-2 p-3`}>
            {seconds < 10 ? `0${seconds}` : seconds}
          </span>
          seg
        </li>
      </ul>
    </section>
  );
};

export default GarantiaRef;
