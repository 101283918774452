'use client';

import Image from 'next/image';
import { useState, useEffect } from 'react';

const Garantia = ({ data }: any) => {
  const initialHours = 2;
  const initialMinutes = 30;
  const initialSeconds = 0;

  const [hours, setHours] = useState(initialHours);
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const timer = setInterval(() => {
      if (hours === 0 && minutes === 0 && seconds === 0) {
        setHours(initialHours);
        setMinutes(initialMinutes);
        setSeconds(initialSeconds);
      } else if (seconds === 0) {
        if (minutes === 0) {
          setHours(hours - 1);
          setMinutes(59);
          setSeconds(59);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [hours, minutes, seconds]);

  return (
    <section className="py-6 px-4 text-lg mb-10 text-center">
      <h2 className="font-bold text-2xl text-gray-800 mb-4">
        {data?.mensagemOferta}
      </h2>
      <ul className="flex items-center justify-center my-10 mx-auto gap-6">
        <li className="flex flex-col items-center">
          <span className="border-2 border-red-500 rounded-full font-bold mb-2 p-4 text-2xl bg-white shadow w-20 h-20 flex items-center justify-center">
            {hours < 10 ? `0${hours}` : hours}
          </span>
          <span className="text-sm text-gray-600">horas</span>
        </li>
        <li className="flex flex-col items-center">
          <span className="border-2 border-red-500 rounded-full font-bold mb-2 p-4 text-2xl bg-white shadow w-20 h-20 flex items-center justify-center">
            {minutes < 10 ? `0${minutes}` : minutes}
          </span>
          <span className="text-sm text-gray-600">minutos</span>
        </li>
        <li className="flex flex-col items-center">
          <span className="border-2 border-red-500 rounded-full font-bold mb-2 p-4 text-2xl bg-white shadow w-20 h-20 flex items-center justify-center">
            {seconds < 10 ? `0${seconds}` : seconds}
          </span>
          <span className="text-sm text-gray-600">segundos</span>
        </li>
      </ul>
      <h3 className="text-3xl font-bold">{data?.mensagemGarantia}</h3>

      <div className="flex flex-col md:flex-row items-center justify-between mt-6 gap-4 text-base md:text-justify">
        <Image
          src={data?.imagemGarantia}
          width={150}
          height={150}
          alt="garantia"
          className="max-w-xs max-h-xs"
          unoptimized
        />
        <p className="p-4">{data?.descricaoGarantia}</p>
      </div>
    </section>
  );
};

export default Garantia;
