import { ChevronDownIcon } from '@heroicons/react/solid';
import style from './Perguntas.module.css';

interface PerguntaItem {
  id: number;
  title: string;
  text: string;
}

interface PerguntasData {
  titulo: string;
  items: PerguntaItem[];
}

const Perguntas = ({ data }: { data: PerguntasData }) => {
  return (
    <section className="py-6 px-4 mb-14 font-bold w-full">
      <h2 className="mb-10 text-2xl text-center">{data?.titulo}</h2>
      <ul className="flex flex-col bg-white rounded-lg p-3">
        {data?.items.map((item) => (
          <li key={item.id}>
            <div className="p-2 cursor-pointer">
              <h4
                onClick={() => {
                  const accordion = document.getElementById(
                    `accordion-${item.id}`
                  );
                  const icon = document.getElementById(`icon-${item.id}`);
                  if (accordion && icon) {
                    accordion.classList.toggle('hidden');
                    icon.classList.toggle('rotate-180');
                  }
                }}
                className={`${style.titleBorder} cursor-pointer py-3 px-2 text-xs md:text-base text-start text-uppercase border-b-2 bg-slate-200 flex justify-between items-center`}>
                {item.title}
                <span id={`icon-${item.id}`}>
                  <ChevronDownIcon className="w-6 h-6 transition-transform duration-300" />
                </span>
              </h4>
              <p
                id={`accordion-${item.id}`}
                className="hidden my-4 mx-auto leading-6 sm:leading-7 md:leading-8 lg:leading-9 font-normal">
                {item.text}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Perguntas;
