const AvisoDesconto = ({ data }: any) => {
  return (
    <div className="flex justify-center text-center bg-black text-white p-2 font-bold text-sm">
      <p className="animate-scale w-4/5 text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">
        {data?.popUpDesconto}
      </p>
    </div>
  );
};

export default AvisoDesconto;
