import ProductCard from '../contentPitch/productCard/ProductCard';
// import Garantia from '../garantia/Garantia';
import GarantiaRef from '../garantia/GarantiaRef';
import Testemunhas from '../testemunhas/Testemunhas';
import Perguntas from '../perguntas/Perguntas';
import { Container } from '../../../../container/Container';

const Main = ({ data }: any) => {
  return (
    <main style={{ backgroundColor: '#ddd' }}>
      <Container>
        <ProductCard data={data?.produtos} />
        {/* <Garantia data={data?.bannerGarantia} /> */}
        <Testemunhas data={data?.testemunhas} />
        <ProductCard data={data?.produtos} />
        <GarantiaRef data={data?.bannerGarantia} />
        <Perguntas data={data?.faqs} />
        <ProductCard data={data?.produtos} />
        {/* <GarantiaRef /> */}
      </Container>
    </main>
  );
};

export default Main;
