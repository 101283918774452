'use client';

import React, { useEffect, useState } from 'react';

const Timer: React.FC = () => {
  const [time, setTime] = useState<number>(1799); // 29 minutos e 59 segundos
  const [isRunning, setIsRunning] = useState<boolean>(true);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isRunning && time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time === 0) {
      setIsRunning(false);
      setTime(1799); // Redefine o tempo para 29 minutos e 59 segundos
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isRunning, time]);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours.toString().padStart(2, '0')} : ${minutes
      .toString()
      .padStart(2, '0')} : ${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="sm:text-3xl text-xl bg-red-500 text-white font-bold py-1 sm:py-4 px-6 sm:px-10 rounded-3xl animate-scale">
      {formatTime(time)}
    </div>
  );
};

export default Timer;
