import Image from 'next/image';
import { memo } from 'react';

// Interface para definir a estrutura dos dados de uma testemunha
interface TestimonyData {
  nomeTestemunha: string;
  nomeLocalizacao: string;
  textoDepoimento: string;
  fotoTestemunha: string;
}

// Interface para definir a estrutura das props do componente Testemunhas
interface TestimonyProps {
  data: TestimonyData[];
}

// Função de renderização para uma única testemunha
const RenderTestimony = memo(
  ({
    nomeTestemunha,
    nomeLocalizacao,
    textoDepoimento,
    fotoTestemunha
  }: TestimonyData) => (
    <div className="flex flex-col md:flex-row items-center md:items-start gap-6 p-6 bg-white rounded-lg shadow-md border-2 border-gray-300">
      {/* Div para conter o texto da testemunha */}
      <div className="flex flex-col items-center md:items-start gap-4">
        <p className="text-sm md:text-base text-justify text-gray-700">
          {nomeTestemunha}, {nomeLocalizacao}
        </p>
        <h2 className="font-semibold italic text-lg md:text-xl lg:text-2xl text-gray-900">
          &#34;{textoDepoimento}&#34;
        </h2>
      </div>
      {/* Div para conter a imagem da testemunha e a avaliação */}
      <div className="flex flex-col items-center text-center gap-3">
        <Image
          src={fotoTestemunha}
          alt="Testemunha"
          width={120}
          height={120}
          className="rounded-full"
          loading="lazy"
          unoptimized
        />
        <Image
          src={'/avaliacao.png'}
          alt="Avaliação"
          width={60}
          height={60}
          loading="lazy"
        />
        <p className="text-sm md:text-base text-gray-700">{nomeTestemunha}</p>
      </div>
    </div>
  )
);

// Adicionando displayName para o componente memoizado
RenderTestimony.displayName = 'RenderTestimony';

// Componente principal Testemunhas
const Testemunhas = ({ data }: TestimonyProps) => {
  return (
    // Seção para conter todas as testemunhas
    <section
      aria-label="Testemunhas"
      className="flex flex-col items-center gap-8 p-4 md:p-8">
      {/* Mapeamento sobre os dados das testemunhas para renderizá-las */}
      {data?.map((testimony, index) => (
        <RenderTestimony
          key={index} // Chave única para cada elemento renderizado
          nomeTestemunha={testimony.nomeTestemunha}
          nomeLocalizacao={testimony.nomeLocalizacao}
          textoDepoimento={testimony.textoDepoimento}
          fotoTestemunha={testimony.fotoTestemunha}
        />
      ))}
    </section>
  );
};

export default Testemunhas;
